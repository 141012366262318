@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;vertical-align: baseline;}

html, body, #root{
    height: 100vh;
}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

ol, ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display: block;
}

embed,iframe,object {
    max-width: 100%;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Poppins', sans-serif;
    background: #fff;
    font-weight: 400;
    height: 100vh;
}

option {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
}

cite,em,i {
    font-style: italic;
}

input[type="text"],input[type="email"],input[type="search"],input[type="password"],input[type="url"],textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],input[type="email"],input[type="password"],input[type="tel"],input[type="url"],textarea,select {
    padding: 5px 10px 5px 0;
    border: 0;
    border-bottom: 1px solid #c1c3c5;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius:0;
    height: 40px;
    font-size: 15px;
    color: #241e21;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

input[type="reset"], input[type="button"], input[type="submit"], .button, button {
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    margin: 15px 0px 10px 0px;
    padding: 14px 26px 14px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #157ee4;
    border: 1px solid #cbcbcb;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 4px;
    font-weight: 500;
    box-shadow:0px 7px 9px 0px rgba(0, 0, 0, 0.21);
}

input[type="reset"]:hover, input[type="button"]:hover, input[type="submit"]:hover, .btn:hover, .button:hover, button:hover {
    color: #fff;
    background: #000;
}

input[type="reset"]:active,input[type="button"]:active,input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #157ee4;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #000;
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0px 0px 15px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

h1 { font-size: 40px;}
h2 { font-size: 30px;}
h3 { font-size: 26px;}
h4 { font-size: 20px;}
h5 { font-size: 17px;}
h6 { font-size: 16px;}

.textcenter {
    text-align: center
}

*, *:before, *:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox], input[type=radio] {
    --active: #157ee4;
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, 0);
    --border: #d0d2d3;
    --border-hover: #157ee4;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #999;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 20px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 2px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    top: 0;
    margin-right:8px;
  }
  input[type=checkbox]:after, input[type=radio]:after {
    content: "";
    display: block;
    left: 0px;
    top: 0px;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked, input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled, input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked, input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled), input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus, input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch), input[type=radio]:not(.switch) {
    width: 20px;
  }
  input[type=checkbox]:not(.switch):after, input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked, input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label, input[type=radio] + label {
    line-height: 20px;
    display: inline-block;
    cursor: pointer;
  }

  input[type=checkbox]:not(.switch) {
    border-radius: 2px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 10px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 2px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 2px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 5px;
    height: 10px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 2px;
    transform: rotate(var(--r, 25deg));
  }
  input[type=radio]:checked {
    --s: .5; --r: 43deg;
  }
}

.step-one {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
}

.so-lhs {
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.so-lhs img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.control-group{position: relative; z-index: 1;margin-bottom: 10px}

.foucs-border div{display: none}
.control-group .input-box {
    background: none;
    font-weight: 500;
}
.control-group label {
    position: absolute;
    left: 0;
    width: 100%;
    top: 9px;
    color: #bfbdbe;
    transition: all 0.3s linear;
    z-index: -1;
    padding-top: 0;
}
.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #606061;
    transition: 0.4s;
}

.input-box:focus ~ label, .focused label {
    top: -14px;
    font-size: 12px;
    transition: all 0.3s linear;
    font-weight: 500;
}
.input-box:focus ~ .focus-border {
    width: 100%;
    transition: 0.4s;
}

.so-rhs {
    width: 75%;
    padding: 120px 20px 120px 80px;
    display: flex;
    flex-flow: column;
    margin-left: 25%;
}

.so-form .button{ width: 100%; }
.terms-chk {font-size: 13px; color: #595656; font-weight: 500;}
.terms-chk a{ color: #595656;  border-bottom: 1px solid #595656; }

.form-header h1{
    font-size: 38px;
    margin-bottom: 10px;
}

.form-header p{ font-size: 19px; }
.form-header{ margin: 0 0 25px 0; }
footer {
    padding: 22px;
    background: #010101;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 14px;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.prompt-box .input-box-small {
    border: 1px solid #c1c3c5;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    padding: 5px;
    font-size: 24px;
    text-align: center;
    margin: 0 5px;
}

.verification-code {
    text-align: center;
}

.prompt {
    font-weight: 500;
    margin: 0 0 10px 0;
}

.dashboard-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
}

.dashboard {
   height: 100vh;
}

.sidebar {
    width: 272px;
    height: 100%;
    background: #010101;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.dashboard-content {
    width: calc( 100% - 272px );
    padding: 40px;
}
.sidebar-nav-logo {
    padding: 20px 10px;
    text-align: center;
}
.sidebar-nav-links ul, .sidebar-nav-btm ul{
    padding: 0;
    margin: 0;
}
.sidebar-nav-links ul li, .sidebar-nav-btm ul li{ margin-bottom: 1px; list-style: none; position: relative;}
.sidebar-nav-links ul li a, .sidebar-nav-btm ul li a{ display: block; padding: 10px 15px; color: #fff; font-size: 15px; font-weight: 500; }
.sidebar-nav-links ul li a:hover, .sidebar-nav-btm ul li a:hover, .mobile-menu .menu a:hover, .sidebar-nav-links ul li.active a, 
.sidebar-nav-btm ul li.active a, .mobile-menu .menu li.active a{ background: #157ee4; color: #fff; }

.sidebar-nav-links ul li a:before,
.sidebar-nav-btm ul li a:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background: #157ee4;
    height: 100%;
    width: 0;
    border-radius: 0 8px 8px 0;
}

.sidebar-nav-links ul li.active>a:before, .sidebar-nav-links ul li a:hover:before,
.sidebar-nav-btm ul li.active>a:before, .sidebar-nav-btm ul li a:hover:before {
    width: 103%;
}

.stepper-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0;
    list-style: none;
    margin: 0 0 30px 0;
}

.sidebar-nav-links ul li a, .sidebar-img-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-img {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
}

.sidebar-img img {
    max-height: 20px;
    margin: auto;
}

.sidebar-img img {
    display: none;
}

.sidebar-img img:first-child {
    display: block;
}

.sidebar-nav-links li.active a .sidebar-img img:first-child, .sidebar-nav-links li a:hover .sidebar-img img:first-child,
.sidebar-nav-btm li.active a .sidebar-img img:first-child, .sidebar-nav-btm li a:hover .sidebar-img img:first-child,
.mobile-menu li.active a .sidebar-img img:first-child, .mobile-menu li a:hover .sidebar-img img:first-child{
    display: none;
}

.sidebar-nav-links li.active a .sidebar-img img:last-child, .sidebar-nav-links li a:hover .sidebar-img img:last-child,
.sidebar-nav-btm li.active a .sidebar-img img:last-child, .sidebar-nav-btm li a:hover .sidebar-img img:last-child,
.mobile-menu li.active a .sidebar-img img:last-child, .mobile-menu li a:hover .sidebar-img img:last-child{
    display: block;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #f4f8fb;
    font-size: 0;
    border: 1px solid #d7dee8;    
    cursor: pointer;
}
.active .step-counter {
    background-color: #157ee4;
    border: 1px solid #157ee4;  
}
.stepper-wrapper:before {
    height: 6px;
    background: #d7dee8;
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    margin: 0 auto;
    top: 10px;
}
.stepper-wrapper:after {
    height: 6px;
    background: #157ee4;
    content: "";
    position: absolute;
    left: 15px;
    margin: 0 auto;
    top: 10px;
    z-index: 1;
        border-radius: 20px;
}
.active-first:after {
    width: 25%
}
.active-second:after {
    width: 50%
}
.active-third:after {
    width: 80%
}
.active-four:after {
    width: 98%
}

.getstarted-inner{
    display: flex;
    flex-wrap: wrap;
}

.getstarted-lhs {
    width: 60%;
    box-shadow:1px 1px 29px 0px rgba(0, 0, 0, 0.13);
    padding: 40px 60px;
     border-radius: 8px;
}

.getstarted-rhs {
    width: 40%;
    padding: 40px 0 20px 60px;
}

.control-country label{ top: -20px; font-size: 12px; }

.getstarted-form .css-d8oujb{
    width: 0;
}
.getstarted-header{text-align: center; margin-bottom: 50px;}
.getstarted-header h2{ font-weight: bold; margin: 0 0 2px 0;font-size: 38px}
.getstarted-header p{ font-weight: bold; margin: 0;font-size: 18px;}
.btn-single { display: flex; margin-top: 30px;}

.btn-single .button{
    width: 100%;
    margin-top: 0;
}
.btn-single .button, .btn-two .button{margin-bottom: 0}
.ghost-button{ background: #fff; color: #157ee4; border-color: #157ee4 }
.btn-two {
    display: flex;
    justify-content: space-between;
}
.control-group-merge{ display: flex; flex-wrap: wrap;}
.control-group-merge .control-group{ width: 30%; margin-right: 4% }

.qty_bx {
    width: 160px;
    height: 46px;
    position: relative;
    padding: 0 50px;
    display: inline-block;
    border: 2px solid #e5e5e5;
    border-radius: 8px;
    overflow: hidden;
}

.qty_bx span {
    position: absolute;
    width: 50px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #fff;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
     border: 0;
}

.qty_bx span.qty_minus {
    left: 0px;
    border-right: 2px solid #e5e5e5;
}

.qty_bx span.qty_plus {
    right: 0px;
    border-left: 2px solid #e5e5e5;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #000;
    font-size: 17px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    font-weight: bold;
}

.qty_bx span.qty_minus:before{
    position: absolute;
    content: "";
    background: #000;
    width: 14px;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

.qty_bx span.qty_plus:before, .qty_bx span.qty_plus:after {
    position: absolute;
    content: "";
    background: #000;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}
.qty_bx span.qty_plus:before{
    width: 14px;
    height: 2px;
}
.qty_bx span.qty_plus:after{
    width: 2px;
    height: 14px;    
}
.outlet-choose {
    text-align: center;
    margin: 10px auto 30px;
}
.outlet-choose p{margin-bottom: 5px}

.your-package ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:20px 0 0 0;
    list-style: none;
}

.your-package ul li {
    width: 32%;
    text-align: center;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    padding-bottom:110px;
    position: relative;
    border-radius: 10px;
    background: #fff;
    z-index: 2;
    overflow: hidden;
    border: 2px solid transparent;
}

.your-package ul li.active .package-btn, .your-package ul li:hover .package-btn{
    border: 1px solid #000;
    color: #fff;
    background: #000;
    cursor: pointer;
}

.package-img{
    height: 280px;
    display: flex;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
    margin: 20px 0;
}

.package-img img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.package-content {
    padding: 17px 13px 18px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 280px);
    background: #fff;
    font-size: 12px;
    color: #373434;
    font-weight: 300;
    line-height: 1.3;
    padding: 30px 10px 0 10px;
}

.package-li-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 100%;
}

a.button.package-btn {
    width: 100%;
    margin: 0;
    border-radius: 10px;
    border: 1px solid #157ee4;
    padding: 15px 26px;
}

a.button.package-btn:hover{
    border: 1px solid #000;
}

.package-content span{ font-weight: 500; font-style: italic;display: block; margin-bottom: 10px}

.package-content h3{ font-size: 20px; }
.your-package ul li:nth-child(2) .package-img {
    display: flex;
    justify-content: flex-end;
}
.your-package ul li:nth-child(2) {
    padding-right: 0;
}

.your-package ul li.active, .your-package ul li:hover{
    border: 2px solid #157ee4;
    cursor: pointer;
}

.package-details-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pdt-lhs {
    width: 353px;
}

.pdt-rhs {
    width: calc( 100% - 353px );
    padding: 0 0 0 60px;
}
.pdt-rhs-top{ font-size: 12px; color: #373434}
.pdt-rhs-btm{padding: 30px 0 0 0;}
.pdt-rhs-btm ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-between;
}

.pdt-rhs-btm h4{ font-size: 16px;}
.pdt-rhs-top h3{font-size: 20px;}
.pdt-rhs-top em{ font-weight: bold; display: block; margin: 0 0 10px 0;}
.package-details-plan {
    border: 1px solid #157ee4;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex-wrap: wrap;
}

.package-details-rhs {
    width: 244px;
    background: #010101;
    border-radius: 40px;
    color: #fff;
    position: relative;
    padding: 0 0 74px 0;
    box-shadow:-2px -1px 18px 0px rgba(0, 0, 0, 0.15);
}

.package-details-lhs {
    width: calc( 100% - 244px );
    padding: 45px 40px 20px 80px;
   
}
.blue-part {
    background: #157ee4;
    height: 100%;
        padding: 20px 22px 10px 22px;
    border-radius: 40px;
    text-align: center;
}

.black-part .button {
    min-width: 160px;
    border: 2px solid #157ee4;
    margin: 0;
    padding: 14px 22px;
}
.black-part .button:hover{
    border: 2px solid #157ee4;
    background: #fff;
    color: #157ee4;
}

.inquire-part .button{
    border: 2px solid #157ee4;
    background: #fff;
    color: #157ee4;
    min-width: auto;
}

.inquire-part .button:hover {
    border: 2px solid #157ee4;
    background: #157ee4;
    color: #fff;
}

.blue-part-inner {
    display: flex;
    justify-content: center;
    flex-flow: column;
    height: 100%;
}

.inquire-part{
    margin-top: 40px;
}

.blue-part-inner h5 {
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 0 0 8px 0;
    text-transform: uppercase;
    font-size: 20px;
}

.pricesection p {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    padding: 2px 0 0;
}

.pricesection p sup {
    top: -8px;
    font-size: 70%;
}
.pricesection span {
    font-size: 12px;
}
.pricesection {
    text-transform: uppercase;
}
.outlet-number {
    padding: 10px 0 0 0;
}
.outlet-number p {
    margin-bottom: 5px;
    font-weight: 500;
    color: #373737;
}

.cards{
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e9ec;
    border-radius: 20px;
    position: relative;
}
.card-header {
    padding: 17px 24px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    border-bottom: 1px solid #e6e9ec;
}
.card-body{padding: 30px;}
.package-details-lhs h5{ font-size: 15px; padding-bottom: 10px;}
.billing-chk {
    margin: 0 0 30px 0;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.4px;
}
.control-three-merge {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.halfplus {
    width: 40%;
}

.half {
    width: 28%;
}
.control-group.halfplus {
    width: 40%;
}

.control-group.half {
    width: 28%;
}

.checkout-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0 0 0;
}

.checkout-main-lhs {
    width: 47.5%;
}

.checkout-main-rhs {
    width: 47.5%;
}
.billing-card .card-body{
    color: #060203;
    text-align: center;
    font-size: 13px;
}
.billing-card .card-body p{ line-height: 1.2; }
.billing-card .button {
    background: #3fba73;
    min-width: 180px;
    margin-bottom: 0;
}
.billing-card h5 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 15px;
}
.billing-card img{ margin:0 auto 20px auto}
.card-footer {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    border-top: 1px solid #e6e9ec;
}
.order-summary{margin-bottom: 25px;}
.order-summary .card-body {
    min-height: 295px;
}
.cart-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-det-rhs {
    width: 160px;
}

.product-det-lhs {
    width: calc( 100% - 165px );
}
.product-det-lhs p{font-weight: 500;line-height: 1;}
.product-det-lhs span{font-size: 12px; text-transform: uppercase}

.remove{display: none;}

/*********************************** SURESH START CSS**********************************/

.dash_heading{
    text-align: center;
    margin-bottom: 20px;
}

.dash_heading h1{
    margin: 0;
}

.dash_heading p{
    text-transform: capitalize;
    font-weight: 600;
}

.dashboard_tab_main{
    max-width: 950px;
    margin: 0 auto;
    box-shadow: 0 3px 28px 0 rgb(1 1 1 / 14%);
    border-radius: 15px;
}

.dashboard_top img, .mobile_tab img {
    margin: 0 auto;
    max-width: 32px;
    height: 29px;
    opacity: 0.3;
}

.dashboard_top li.active a .dash_type_img, .mobile_tab a.active .dash_type_img{
    opacity: 1;
}

.dashboard_top li.active a{
    border-radius: 15px 15px 0 0;
    background: #fff;
    color: #000;
}

.mobile_tab a.active{  background: #fff; color: #000;}

.dashboard_top li:nth-child(4n) a{
    border-radius: 0px 15px 15px 0px;
}

.dashboard_top li:nth-child(4n){
    background: #fff;
    border-radius: 0px 15px 0px;
}

.dash_img{
    margin-bottom: 5px;
}

.dashboard_top ul{
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.dashboard_top li{
    list-style: none;
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #f6f5f8;
    opacity: 0.8;
}

.dashboard_top ul{
    border-radius: 15px 15px 15px 0;
}

.dashboard_top li a{
    color: #bebdc0;
    font-weight: 500;
    text-transform: capitalize;
    background: #f6f5f8;
    padding: 15px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
}

.settings-content{
    padding: 30px 0 50px;
}

.dash_in_heading{
    margin-bottom: 20px;
}

.dash_in_heading h4{
    margin-bottom: 10px;
    text-transform: capitalize;
}

.settings-content .so-form{
    margin: 0 auto;
}

.settings-content .so-form .button{
    width: auto;
    min-width: 190px;
    z-index: initial;
}

.settings-content .control-group{
    text-align: left;
}

.backendinfo-lhs{
    width: 50%;
}

.backendinfo-rhs{
    width: 50%;
    padding: 70px 20px 70px 50px;
}

.backendinfo_img img{
    width: 100%;
    height: 100%;
}

.backendinfo-content h4{
    text-transform: capitalize;
    margin-bottom: 15px;
}

.backend-group label{
    margin-bottom: 10px;
}

.backendinfo-content .own-two{
    min-width: 255px;
    background: #fff;
    color: #157ee4;
    margin: 0px;
}

.backendinfo-content .own-two:hover{
    background: #000;
    color: #fff;
}

.backendinfo-group{
    display: flex;
    align-items: center;
}

.group-top{
    margin-top: 10px;
}

.backendinfo-group input{
    margin: 0;
    padding: 0;
    border: 0;
    color: #000;
}

.backendinfo-group label{
    min-width: 88px;
    text-transform: capitalize;
}

.frontend-link{
    margin: 20px 0;
}

.frontend-link a{
    border-bottom: 1px solid #157ee4;
}

.frontend-link a:hover{
    color: #000;
    border-bottom: 1px solid #000;
}

.contacttab-para{
    margin-top: 10px;
}

.frontend-para span{
    font-weight: 600;
}

.singupform-step-one .react-tel-input .form-control{
    border: 0;
    border-bottom: 1px solid #CACACA;
    border-radius: 0;
    width: 100%;
    background: none;
}

.control-flag{
    margin-bottom:20px;
    z-index: 2;
}

.phone-label{
    position: absolute;
    left: 80px;
    width: 100%;
    top: 28px;
    color: #bfbdbe;
    transition: all 0.3s linear;
    z-index: -1;
    padding-top: 0;
}

.getstarted-form .two-col{
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.getstarted-form .control-group{
    z-index: unset;
    padding-right: 20px;
}

.singupform-step-one .react-tel-input .flag-dropdown{
    border: 0;
    border-radius: 0;
    background: transparent;
    top: inherit;
    bottom: 18px;
}

.singupform-step-one .error, #form-msg {
    color: red;
    line-height: 1;
    font-size: 11px;
    background: #febaba;
    padding: 5px;
    text-align: center;
    position: relative;
}

.singupform-step-one .error:before, #form-msg:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.get-outlet{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.get-outlet p{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-right: 10px;
}

button.mfp-close, button.mfp-arrow {margin: 0; padding: 0;}

button.mfp-close {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 0;
    color: #afafaf;
    margin: 0;
    opacity: 1;
    background: #000; right:5px; top: 5px; 
    border-radius:5px;min-width: inherit;
}
button.mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}
button.mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}
.mfp-close:active {
    top: 5px;
}

.white-popup.processing {
    max-width: 720px;
    padding: 30px 25px 20px 25px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.control-phone{
    position: absolute;
    width: 100%;
    top: 28px;
    left: 85px;
    color: #bfbdbe;
    z-index: -1;
    transition: all 0.3s linear;
    padding-top: 0;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 98;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.singupform-step-one .terms-chk {
    position: relative;
    margin-bottom: 10px;
}
.singupform-step-one .terms-chk span.error {
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 3;
}

body .css-vj8t7z {
    border-top: 0;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #c1c3c5;
    margin-bottom: 20px;
}

body .css-1hwfws3 {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

body #react-select-2-input {
    height: 40px;
    margin-bottom: 0;
}

.css-1g6gooi {
    margin: 0;
    padding: 0;
}
body .css-2o5izw{
    border: 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #c1c3c5;
}
.package-details-btm {
    border-top: 1px solid #e4e4e4;
    padding: 150px 0 0;
    margin: 35px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.package-details-btm  > h4{ text-align: center; }
.package-details-request {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px 0 0;
    line-height: 1.3;     font-weight: 500;
}
.package-details-request .button{ margin: 0 0 0 20px; }

#enquire-popup {max-width: 480px; background: #fff; border-radius: 20px;  text-align: center; padding: 45px 35px 25px;}
#enquire-popup .alert_header { font-weight: bold; text-transform: capitalize; font-size: 26px;letter-spacing: 1px;}
#enquire-popup .alert_body { padding: 20px 0; }
#enquire-popup .alert_body p:first-child{ margin-bottom: 5px; }
#enquire-popup .alt_btns{ margin-top: 20px; }
#enquire-popup .button{margin: 0; min-width: 85px; font-weight: 600; padding: 14px 24px 12px; border: 2px solid #157ee4;}
#enquire-popup .button:hover{ background: #000; color: #fff; border: 2px solid #000;}

#enquire-popup .button-right{border: 2px solid #157ee4; }

#enquire-popup .button-left{ background: #fff; border: 2px solid #eeeeee; color: #157ee4; margin-left: 20px;}

.white-popup.alert_popup{
    max-width: 480px;
    background: #fff;
    padding: 45px 35px 25px;
    border-radius: 20px;
}

.alert_heading {
    background: #fff;
    color: #000;
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
}

.custom-alrt-popupcls .alert_body{;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.custom-alrt-popupcls .alert_body p{
    font-size: 18px;
}

.dashboard_contant{display: none}
.dashboard_contant{ display: flex; justify-content: space-between; padding: 30px 30px;}

.dashboard_contant.active{ display: flex}
.settings-content-lhs {
    width: 50%;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-content-rhs {
    width: 100%;
    padding: 20px 35px;
}

.special-label{
    display: none;
}

.singupform-step-one .error:before {
    border: none;
}

.dashboard .nav {
    border-bottom: 5px solid #e3e3e3;
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
}

.dashboard .nav-tabs>li {
    width: 50%;
    border-bottom: 0;
    position: relative;
}

.dashboard .nav-tabs>li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #157ee4;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: .25s linear;
}

.dashboard .nav-tabs>li.active:before, .dashboard .nav-tabs>li:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.dashboard .nav-tabs>li.active>a, .dashboard .nav-tabs>li>a, 
.dashboard .nav-tabs>li.active>a:focus, .dashboard .nav-tabs>li.active>a:hover, .dashboard .nav-tabs>li:hover a {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 22px;
    background-color: #fff;
    color: #000;
    border: 0;
    border-bottom-color: transparent;
}

.dashboard .nav-tabs>li>a{
    color: #e3e3e3;
}

.dashboard-ulmain {
    max-width: 580px;
    height: 100%;
}

.dashboard-ulmain .tab-content{
    padding: 0 50px;
}

.dashboard input[type=checkbox]:checked, .dashboard input[type=radio]:checked {
    --b: #000000;
    --bc: #000000;
}

.control-flag .control-label{
    top: 5px;
    left: 85px;
}

.control-flag .form-control {
    background: none;
}

 .control-flag.focused label, .control-flag:focus ~ label {
    top: -15px;
    left: 50px;
}

.control-flag input:focus{
    color: #000;
}

/***********************************/

.mbl_search_liblk, .menu_icon.trigger_menunav_act {
    display: none;
}

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background: #000;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: opacity .2s linear,-webkit-transform .3s ease-in-out;
    transition: opacity .2s linear,transform .3s ease-in-out;
    transition: opacity .2s linear,transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 9999;
    max-width: 520px;
}

.mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #000;
    z-index: 2;
    text-align: right;
    border-bottom: 0.0625rem solid hsla(0,0%,100%,.22);
}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 22px;
    height: 22px;
    position: relative;
}

.mobile-menu .mobile-menu-close span:after, .mobile-menu .mobile-menu-close span:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s,-webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: "";
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    background: #000;
    padding: 50px 40px 40px;
    height: 100%;
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
}

.mobile-menu .menu li {
    list-style: none;
    position: relative;
    border-bottom: 0.0625rem solid hsla(0,0%,100%,.22);
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    color: #fff;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-weight: 700;
    padding: 1rem 20px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
}
 
.control-country{ margin-bottom: 25px; }

.control-group .css-1492t68{ color: #bfbdbe; }

.mobile-nav-logo{ position: relative; left: 50%; transform: translateX(-50%); width: 170px; }

.dashboard-mobilemain{ display: none; }

.package-details-header ul li, .package-middle-header li{
    list-style: none;
}

.package-details-header ul, .package-middle-header ul{
    padding: 0;
}

.package-details-header h4{ 
    position: relative;
    text-transform: capitalize;
}

.package-details-header h4:after{
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
    background: #efefef;
    margin-left: 15px;
}

.package-headingtop {
    width: 100%;
    overflow: hidden;
}

.package-main-box{
    width: 40%;
    height: 100%;
}

.back-package .button{
    min-width: 180px;
    background: #000;
    margin: 50px 0px 0px;
}

.your-package .choose-btn .button{
    min-width: 150px;
    background: #000;
    margin: 50px 0px 0px;
    padding: 14px 26px;
    font-size: 15px;
}

.package-details-main{
    border-radius: 20px 0 0 20px;
    padding: 70px 40px;
    box-shadow: 0px 0px 17px 0px #a1a1a136;
    width: 100%;
    height: 100%;
}

.package-details-bottom li{
    padding: 10px 0;
    text-transform: capitalize;
    font-size: 15px;
    color: #373737;
    font-weight: 600;
}

.package-details-middle, .package-details-lower{ 
    width: 50%;
    text-align: center;
    padding: 50px 15px 20px;
    position: relative;
    border: 2px solid transparent;
    border-radius: 20px;
 }

 .package-middle-container{
    position: relative;
    bottom: 60px;
    display: flex;
    width: 60%;
    border: 1px solid #e4e4e4;
    border-radius: 20px;
 }

 .package-details-middle{
    border: 2px solid #157ee4;
 }

 .package-details-middle h5{
    margin-bottom: 5px;
 }

 .package-headingtop p{
    border-bottom: 2px solid #efefef;
    padding-bottom: 10px;
    color: #878585;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
 }

.package-middle-header h4{
    margin-bottom: 5px;
}

.package-headingcontent h6{
    font-size: 12px;
    margin: 15px 0 0px;
    text-transform: uppercase;
}

.package-headingcontent sup{
    font-size: 22px;
}

.package-headingcontent h1{
    margin-bottom: 0;
}

.package-headingcontent p{
    color: #878585;
    text-transform: uppercase;
    margin: 5px 0;
    font-weight: 500;
}

.p_middle{ color: #157ee4; }

.package-headingcontent .p_middle_price{
    margin-bottom: 38px;
}

.package-headingcontent{
    margin-bottom: 10px;
}

.package-priceul{ display: flex; align-items: center; justify-content: center; flex-flow: column; }
.package-priceul li{
    margin: 10px 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #373737;
}

.package-priceul li img{
    width: 22px;
    height: 23px;
    vertical-align: middle;
}

.U_img {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    border: 1px dashed #d8d7d8;
    border-radius: 15px;
    border-width: 3px;
    padding: 20px 40px;
    width: 49%;
}

.f_uplod{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.control-para {
    min-width: 115px;
    color: #7d797b;
    text-transform: capitalize;
    text-align: center;
}

.U_img .control-para{
    font-size: 14px;
    margin-bottom: 5px;
}

.fU_img_div img{
    margin: 0 auto;
    max-width: 40px;
    max-height: 29px;
}

.dash-btn-div{ margin-top: 10px; }

.U_img_para{
    font-size: 11px;
    color: #7d797b;
    text-align: center;
}

.tab_cont_form .U_img .input-box {
    display: flex;
}

.tab_cont_form .U_img .input-box::-webkit-file-upload-button {
  visibility: hidden;
}
.tab_cont_form .U_img .input-box::before {
    content: 'browse';
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    color: #fff;
    border-radius: 5px;
    margin: 0 50px 10px;
    padding: 14px 26px 14px;
    appearance: none !important;
    cursor: pointer;
    background: #157ee4;
    border: 1px solid #cbcbcb;
    font-size: 15px;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 4px;
    font-weight: 500;
    box-shadow: 0px 7px 9px 0px rgb(0 0 0 / 21%);
   
}
.tab_cont_form .U_img .input-box:hover::before, .tab_cont_form .U_img .input-box:active::before {
  background: #000; 
}

.tab_cont_form .U_img .input-box:active {
  outline: 0;
}

.tab_cont_form .css-d8oujb{
    width: 0;
}

.destop_tab{ display: block; }

.mobile_tab{ display: none; }

.mobile_tab .slick-arrow{
    background: rgb(0 0 0 / 26%);
    border: 0;
    width: 30px;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    text-align: center;
    overflow: hidden;
    font-size: 0;
    color: #fff;
    z-index: 1;
    padding: 0;
}

.mobile_tab .slick-arrow:before{
    content: "\f104";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 25px;
    font-size: 24px;
}

.mobile_tab .slick-next {
    right: 2px;
    left: auto;
}

.mobile_tab .slick-next:before{
    content: "\f105";
}

.mobile_tab a{
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    padding: 10px;
    background: #f8f7f9;
    color: #bebdc0;
}

.backend-image-div img {
    margin: 0 auto;
    max-width: 32px;
    max-height: 25px;
}

.backend-image-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.backend-image-div .dash_img{ margin: 0 0 0 20px; }

.backend-image-div .button{ margin: 0; }

.dash-btn-div {
    display: flex;
    justify-content: flex-end;
}

.backendpass-group input{
    width: 100px;
}

.backendpass-group .dash_img img{
    margin: 0 auto;
    max-width: 32px;
    max-height: 15px;
}

.backendpass-group .dash_img{
    margin-bottom: 0;
}

.checkout-main .back-package .button{
    margin: 20px 0px 0px;
}

span.error_img {
    position: absolute;
    top: 15px;
    right: -25px;
}

.checkout-main-lhs .billing-chk input{
    width: 23px;
    height: 23px;
    border-radius: 50%;
    margin-right: 0;
}

.checkout-main-lhs .billing-chk input[type=checkbox]:not(.switch):after{
    left: 8px;
    top: 3px;
}

.checkout-main-lhs .billing-chk input[type=checkbox]:checked, .checkout-main-lhs .billing-chk input[type=radio]:checked {
    --b: #157ee4;
    --bc: #157ee4;
}

.pdt-rhs-btm .slick-dots, .mobile-package-middle  .slick-dots{
    display: flex!important;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 20px 0;
}

.pdt-rhs-btm .slick-dots li,  .mobile-package-middle .slick-dots li{
    margin: 0 4px;
}

.pdt-rhs-btm .slick-dots li button,  .mobile-package-middle .slick-dots li button{
    font-size: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    background: #000;
    margin: 0;
    border: 0;
    box-shadow: none;
}

.pdt-rhs-btm .slick-dots li.slick-active button,  .mobile-package-middle .slick-dots li.slick-active button{
    background: #157ee4;
}

.pdt-rhs-btm .slick-slide img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mobile-package-middle{ display: none; }

.control-low{ z-index: -1; }

/* =========Plan Package Choosing Start==========  */

.plan-chose-hedaingdiv{ text-align: center; margin-bottom: 40px;}

.plan-chose-innerdiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.plan-chose-innerlhs{
    width: 100%;
    box-shadow: 0px 0px 17px 0px #a1a1a136;
    border-radius: 10px;
    padding: 40px 20px 20px;
    margin-bottom: 20px;
}

.plan-chose-innertop{
    text-align: center;
}

.plan-chose-innertop h4{
    text-transform: capitalize;
    margin-bottom: 5px;
}

.plan-chose-innertop p{
    text-transform: capitalize;
    color: #878585;
    font-size: 16px;
    font-weight: 500;
}

.plan-chose-innerprice{
    text-align: center;
    margin: 20px 0;
}

.plan-chose-innerprice h6{
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
}

.plan-chose-innerprice h1{
    font-size: 26px;
    margin-bottom: 10px;
}

.plan-chose-innerprice sup {
    font-size: 18px;
}

.plan-chose-innerprice p{
    color: #878585;
    text-transform: uppercase;
    margin: 5px 0;
    font-weight: 500;
    font-size: 14px;
}

.plan-drop-btn {
    border-top: 1px solid #efefef;
}

.plan-drop-btn .button{
    box-shadow: none;
    background: none;
    border: none;
    color: #6e6c6c;
    text-transform: capitalize;
    letter-spacing: normal;
    padding: 0;
    margin: 20px 0 0;
    font-size: 14px;
}

.plan-details-div{
    display: none;
    margin: 20px 0;
    padding: 0 0 15px;
    border-bottom: 1px solid #efefef;
}

.plan-details-div li{
    list-style: none;
    padding: 5px 0;
    font-size: 14px;
    text-transform: capitalize;
}

.plan-drop-btn .outlet-price-btn{
    text-align: center;
}

.outlet-price-btn .button{
    min-width: 160px;
    margin: 0;
    padding: 14px 22px;
    box-shadow: 0px 7px 9px 0px rgb(0 0 0 / 21%);
    border: 2px solid #157ee4;
    background: #157ee4;
    color: #fff;
    letter-spacing: 4px;
    font-weight: 500;
    text-transform: uppercase;
}

.hide_btn-plan{
    display: none;
}

.inquire-plan-part{
    text-align: center;
    margin-top: 20px;
}

.inquire-plan-part .button:hover{
    background: #157ee4;
    color: #fff;
}

.inquire-plan-part .button{
    min-width: 160px;
    margin: 0;
    padding: 14px 22px;
    box-shadow: 0px 7px 9px 0px rgb(0 0 0 / 21%);
    border: 2px solid #157ee4;
    background: #fff;
    color: #157ee4;
    letter-spacing: 4px;
    font-weight: 500;
    text-transform: uppercase;
}

.plan-chose-maindiv{ display: none; }

.cat-content{ text-transform: capitalize; }

.captcha-div h6{ margin: 20px 0 15px; }

.terms-chk a:hover{ color: #157ee4; border-bottom: 1px solid #157ee4; }

.common-inner-banner {
    position: relative;
    padding: 20px 10px 10px;
    color: #000;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    background-size: cover;
    min-height: 130px;
    text-align: center;
}

.common-inner-banner p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1;
    color: #000;
}

.cms-content strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
}

.cms-content span{ line-height: 1.5; display: block; color: #535353; font-weight: 500; }

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
}

.warining_popup, .warning_popup{max-width: 450px; background: #fff;}

.f_passwd_div {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.onboard-forgot-password{
    color: #000;
    text-transform: capitalize;
    border-bottom: 1px solid #bcbcbc;
    font-weight: 500;
}

.onboard-forgot-password:hover{
    color: #157ee4;
    border-bottom: 1px solid #157ee4;
}

.Subscrip-heading, .mydetails{
    margin-bottom: 30px;
}

.subscrip-maindiv .package-details, .mydetail_form{
    box-shadow: 0px 0px 17px 0px #a1a1a136;
    border-radius: 20px;
}

.subscription-detail-heading{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #000;
    border-radius: 20px 20px 0px 0px;
    padding: 15px 20px;
    position: relative;
}

.subscrip-maindiv .package-details-top{
    padding: 25px 40px;
    align-items: center;
}

.subscription-detail-lhs h5{
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
}

.subscription-detail-rhs h5{
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
}

.subscription-detail-rhs h5 strong{
    font-weight: 700;
}

.subscription-button{
    display: flex;
    justify-content: space-between;
    padding: 0 40px 25px;
}

.subscription-button .inquire-part{
    margin: 0;
    z-index: 1;
}

.subscription-button .black-part{ z-index: 1; }

.subscrip-maindiv .pdt-lhs {
    width: 205px;
    height: 260px;
}

.subscrip-maindiv img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subscrip-maindiv .pdt-rhs{
    padding: 0 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc( 100% - 205px );
    height: calc( 100% - 200px );
}

.subscrip-maindiv .black-part .button, .mydetail_maindiv .black-part .button{
    padding: 12px 32px 11px
}

.subscrip-maindiv {
    max-width: 630px;
}

.subscrip-maindiv .black-part .button{ letter-spacing: 4px; border: 2px solid #eeeeee; }

.subscrip-maindiv .black-part .button:hover, .subscrip-maindiv .black-part .button.active{ border: 2px solid #157ee4; }

.notification-heading h1{
    margin-bottom: 25px;
}

.notification-maindiv ul{
    padding: 0;
}

.notification-maindiv li{
    list-style: none;
    box-shadow: 0px 0px 17px 0px #a1a1a136;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.notification-maindiv li.active .notification-content h5, .notification-maindiv li.active .notification-content p{
    color: #000;
}

.notification-img {
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
}

.notification-img img {
    max-height: 25px;
    min-width: 25px;
}

.notification-li {
    display: flex;
    align-items: flex-start;
}

.notification-content h5{
    margin-bottom: 5px;
    font-weight: 600;
    color: #bbbbbc;
}

.notification-content p{
    font-size: 14px;
    color: #bbbbbc;
}

.notification-button .black-part .button{
    border: 2px solid #e4e4e4;
    background: #fff;
    color: #157ee4;
    min-width: auto;
    margin: 0;
    padding: 12px 22px 11px;
}

.notification-maindiv .clear{
    margin-bottom: 10px;
    text-align: right;
    padding-right: 45px;
}

.notification-maindiv .clear a{
    font-weight: 600;
    text-transform: capitalize;
    font-size: 14px;
    border-bottom: 1px solid #000;
    color: #000;
}

.notification-maindiv .clear a:hover{
    color: #157ee4;
    border-bottom: 1px solid #157ee4;
}

.notification-button .black-part .button:hover, .mydetail_maindiv .black-part .button:hover{
    background: #157ee4;
    border: 2px solid #157ee4;
    color: #fff;
}

.mydetail_maindiv{
    max-width: 630px;
}

.mydetails h1{
    text-transform: capitalize;
}

.mydetail_maindiv .so-form {
    padding: 25px 40px;
}

.mydetail_maindiv .subscription-button {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 15px;
}

.mydetail_maindiv .black-part .button{
    min-width: 265px
}

.mydetail_maindiv .black-part .button {
    letter-spacing: 4px;
    border: 2px solid #eeeeee;
}

.mydetail_maindiv .black-part .button.active{
    border: 2px solid #157ee4;
}

.error_img img{
    height: 20px;
    width: 20px;
}

.error_para {
    background: #ffbaba;
    color: #d8000c;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    z-index: 4;
    line-height: 1.1;
    margin-top: 5px;
}

.error_para:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

#pay-conf-popup{
    max-width: 480px;
    padding: 45px 35px 35px;
    border-radius: 20px;
    text-align: center;
}

.forgot-password-popup {
    max-width: 620px;
    padding: 45px 35px 35px;
    border-radius: 20px;
    text-align: center;
}

.forgot-password-heading{
    background: #fff;
}

.forgot-password-heading h3, #pay-conf-popup .alert_header{
    color: #000;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
}

.forgot-password-heading p, #pay-conf-popup .alert_body p{
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.forgot-popup-inner {
    padding-top: 20px;
}

.forgotpassword-cls{
    width: 100%;
}

.forgot-password-popup label{
    text-align: left;
}

.forget-heading{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

#change-password-popup{
    max-width: 640px;
    padding: 45px 35px 25px;
    border-radius: 20px;
}

#change-password-popup h3 {
    text-align: center;
    font-size: 26px;
    text-transform: capitalize;
}

#change-password-popup label{
    text-align: left;
    font-weight: 400;
}

.plan-video:hover img {
    transform: scale(1.14);
    -webkit-transform: scale(1.14);
}

.plan-video img {
    transition: all .7s cubic-bezier(.2,1,.22,1);
    -webkit-transition: all .7s cubic-bezier(.2,1,.22,1);
    transform: translateZ(0) scale(1.05);
    -webkit-transform: translateZ(0) scale(1.05);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    max-width: none!important;
    width: 100%!important;
}

.plan-video a{
    display: block;
    position: relative;
}

.plan-video{
    box-shadow: rgb(0 0 0 / 4%) 0px 1px 0px, rgb(0 0 0 / 5%) 0px 2px 7px, rgb(0 0 0 / 6%) 0px 12px 22px;
    overflow: hidden;
    margin: 0 5px;
}

/*.plan-video a:before{
    content: "";
    background: url(../images/video-btn.png) no-repeat;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    z-index: 1;
}*/

.plan-video a .video-btn-slider{
    content: "";
    width: 30px !important;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pdt-rhs-btm .plan-video:first-child{
    padding-left: 0;
}

.tab_cont_form .control-group.main_set_pass{
    margin-bottom: 10px;
}

.availabilty_sectiondiv {
    position: relative;
    margin-bottom: 10px;
    font-size: 13px;
    color: #595656;
    font-weight: 500;
    margin-right: 20px;
}

.availabilty_mdiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 0 20px;
}

.availabilty_mdiv h5{
    text-transform: capitalize;
    width: 100%;
}

#video-popup .modal-lg{
    margin: 30px auto;
    max-width: 828px;
    width: 100%;
    transform: translate(0, 25%);
}

#video-popup .modal-detail-box {
    height: 448px;
}

#video-popup iframe{
    width: 100%;
    height: 100%;
}

#video-popup .common-modal-head{
    position: relative;
}

#video-popup .common-modal-head .close{
    position: absolute;
    right: -40px;
    top: -40px;
    background: #000000bd;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 2px solid #000000;
}

#video-popup .common-modal-head .close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

#video-popup .common-modal-head .close:hover{
    background: #000;
    border: 2px solid #000;
}

#video-popup.modal.fade .modal-dialog {
    transition: -webkit-transform .5s ease-out;
    transition: transform .5s ease-out;
    transition: transform .5s ease-out,-webkit-transform .5s ease-out;
    -webkit-transform: translate(0,100%);
    -ms-transform: translate(0,100%);
    -o-transform: translate(0,100%);
    transform: translate(0,100%);
}

#video-popup.modal.in .modal-dialog {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.sidebar.sticky{
    justify-content: flex-start;
    position: fixed;
    top: 0;
}

.enterpriselite-div h3{
    text-transform: capitalize;
}

.enterprise-middle-div{
    width: 295px;
}

.enterprice-mainbox{
    width: calc(100% - 295px);
}

.enterprice-details-lower{
    width: 100%;
}

.enterprice-details-bottom .package-details-li{
    font-weight: 700;
    padding-left: 0;
}

.enterprice-details-bottom li{
    font-weight: 500;
    padding-left: 25px;
}

.enterprice-details-lower .p_middle_price {
    margin-bottom: 10px;
}

.enterprice-priceul li {
    padding: 10px 0 9px;
    margin: 0;
}

.bod_link{
    margin-bottom: 20px;
    border-bottom: 1px solid #157ee4;
    color: #157ee4;
    display: inline-block;
}

.bod_link:hover{
    border-bottom: 1px solid #000;
    color: #000;
}

.enterprice-details-lower .epric-btn, .enterprice-details-middle .epric-btn{
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.enterprice-details-main{
    box-shadow: -10px 1px 17px 0px #a1a1a136;
}

.login_mobileheader{
    display: none;
}

.sidebar-nav-top{
    position: sticky;
    top: 0;
}

.input-link_box{
    margin: 20px 0 10px;
    width: 100%;
}

#tab-id-proinn4 {
    min-height: 100vh;
}

.ninjalite-package .lite-heading{
    font-weight: 700;
    padding-left: 0;
}

.ninjalite-ul li{
    color: #363435;
    padding-left: 25px;
}

.ninjapro-div .black-part.inquire-part{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 98;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.subscription-popup{
    max-width: 640px;
    padding: 45px 35px 25px;
    border-radius: 20px;
}

.subscription-popup .mfp-close, #change-password-popup .mfp-close, .alert_popup .mfp-close, #enquire-popup .mfp-close,
.forgot-password-popup .mfp-close, #pay-conf-popup .mfp-close{
    background: #fff;
}

.subscription-popup .mfp-close:hover, #change-password-popup .mfp-close:hover, .alert_popup .mfp-close:hover, 
#enquire-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover, #pay-conf-popup .mfp-close:hover {
    background: rgb(255 255 255 / 90%);
}

.subscription-popup .mfp-close:after, #change-password-popup .mfp-close:after, .alert_popup .mfp-close:after, 
#enquire-popup .mfp-close:after, .forgot-password-popup .mfp-close:after, #pay-conf-popup .mfp-close:after {
    content: "";
    background: url(../images/crosslgblack.png) no-repeat;
    width: 14px;
    height: 14px;
}

.subscription-heading h3{
    text-align: center;
    font-size: 24px;
}

.subscription-popup textarea{
    height: 105px;
    border: 1px solid #c1c3c5;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-appearance: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 10px;
    color: #000;
    width: 100%;
    display: block;
}

.subscription-select{
    font-weight: 500;
    color: #000;
}

.subscription-popup .subscription-select {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    background: url(../images/sub_arrow.png) no-repeat 100% #fff;
    background-position: calc(100% - 10px) 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 35px 5px 0;
}

.bod_link.ninjapro{
    display: -webkit-inline-box;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}

.ninjapro_psg .inquire-part{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

/***************** 06-04-22 ********************/

.fileup_lhs, .fileup_rhs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.fileup_content {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.fileup_link {
    width: 50%;
}

.fileup_link .control-group{
    margin-bottom: 0;
}

.fileup_link  input[type="text"]{
    margin-bottom: 0;
}

.uploaded-para {
    min-width: 125px;
    color: #241e21;
    text-transform: capitalize;
    text-align: left;
    font-weight: 500;
}

.myacc_cmdsdiv{
    margin: 30px 0 20px;
}

.myacc_cmdsdiv  h5{
    text-transform: capitalize;
}

.myacc_cmdsdiv textarea{
    display: block;
    border-radius: 8px;
    padding: 6px 18px;
    margin: 0;
    height: 150px;
    border: 1px solid #efefef;
    background: #fff;
    font-size: 15px;
}

.myacc_cmdsinner{
    margin-bottom: 30px;
}

.fileup_container{
    margin-bottom: 20px;
}