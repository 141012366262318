
@media (max-width: 1200px){
	.getstarted-lhs{padding: 40px 30px;}

	.package-main-box{ width: 60%; }
	.package-middle-container{ width: 40%; }
	.package-details-main {padding: 40px 20px;}

	.mobile-enterprice-middle{ width: 300px;}
	.enterprice-mainbox{ width: calc(100% - 300px);}

	.destop-package-middle{ display: none; }
	.mobile-package-middle{ display: block;  border: 0; position: relative; top: -100px;}

	.mobile-package-middle .package-details-middle, .mobile-package-middle .package-details-lower{ width: 100%; }

	.package-details-lower{ border: 2px solid #157ee4; }

	.enterprice-details-lower .p_middle_price { margin-bottom: 38px;}

}
@media (max-width: 1080px){
	.so-rhs { padding: 20px 20px 120px 40px;}
	.getstarted-lhs{padding: 40px 20px;}

	.menu_icon.trigger_menunav_act { display: block; width: 30px; height: 19px; margin: 3px 0; padding: 0; cursor: pointer; }
    .menu_icon .icon-bar { display: block; background-color: #fff; height: 2px; transition: all .4s; }
    .menu_icon .icon-bar+.icon-bar { margin-top: 6px; }

    .dashboard-mobilemenu{
        width: 100%;
        height: 70px;
        padding: 10px 15px;
        background: #010101;
        display: flex;
        align-items: center;
        position: sticky;
    	top: 0;
    	z-index: 6;
    }

	.sidebar{ display: none; }
	.dashboard-mobilemain{ display: block; position: sticky; top: 0; width: 100%; z-index: 10;}
	.dashboard-content { width: 100%; }

	.package-details-main {padding: 70px 20px;}
}

@media (max-width: 1024px){
	.dashboard-ulmain .tab-content { padding: 0 30px;}
}

@media (max-width: 991px){

	.getstarted-header h2{ font-size: 32px; }
	.getstarted-header p{ font-size: 16px; }

	.getstarted-rhs{ order: 1; width: 100%; padding: 40px 20px; max-width: 400px; margin: 0 auto; display: none;}
	.getstarted-lhs{ order: 2; width: 100%; }
	.get-outlet{ justify-content: flex-start; align-items: center; }
	.get-outlet p{ margin-right: 15px; }

	.form-header p br{ display: none; }
	.form-header h1{ font-size: 28px; }
	.form-header p { font-size: 16px;}

	.dashboard .nav-tabs>li.active>a, .dashboard .nav-tabs>li>a, .dashboard .nav-tabs>li.active>a:focus, 
	.dashboard .nav-tabs>li.active>a:hover, .dashboard .nav-tabs>li:hover a{ font-size: 18px; }

	.your-package h1{ font-size: 32px; }

	.inquire-part .button{ letter-spacing: 0.8px; }
	
	.dashboard-content{ padding: 20px; }

	.form-header { margin: 0 0 20px 0;}

	#video-popup .modal-content { margin: 0 40px;}
}

@media (max-width: 980px){
    .mobile-menu .menu {  margin: 0 0 30px;}
 }

@media (max-width: 780px){

	.so-rhs{ padding: 20px 20px 120px; }
	.dashboard-ulmain{max-width: 100%;}
	.dashboard-ulmain .tab-content { padding: 0 20px;}

	.prompt-box .input-box-small{ width: 40px; height: 40px; }

	.your-package ul li { width: 48%; margin-bottom: 20px;}

	.pdt-rhs, .pdt-lhs{ width: 100%; padding: 0; }

	.pdt-lhs{ text-align: center; }

	.package-details-main{ padding: 40px 20px; }

	.inquire-part .button{ letter-spacing: 4px; }

	.pdt-lhs{ margin-bottom: 20px; }

	.checkout-main-lhs{ width: 100%; margin-bottom: 25px;}
	.checkout-main-rhs{ width: 100%; }
	.checkout-main{ flex-flow: column; justify-content: center; }

	.backendinfo-lhs, .backendinfo-rhs{ width: 100%; }

	.backendinfo-lhs{ padding-right: 20px; }

	.backendinfo-rhs{ padding: 40px 0 20px; }

	.settings-content-rhs { padding: 20px 10px;}

	.common-inner-banner {  min-height: 100px;}

	.forgot-password-popup .pop-whole-lhs-inner h3{ font-size: 28px; }

	.U_img{ padding: 20px; }
}

@media (max-width: 680px){
	.destop_tab{ display: none; }
	.mobile_tab{ display: block; }

	.settings-content-rhs{ padding: 0; }

	.dash_in_heading{ text-align: center; }
	.dash_heading h1{ font-size: 28px; }

	.settings-content .so-form .button { min-width: 240px; margin: 15px auto 0; display: block;}

	.U_img{ padding: 20px 10px;}

	.frontend-button { display: flex; justify-content: center; flex-flow: column; width: 100%;}

	.dashboard_contant{ flex-flow: column; justify-content: center; }
	.dashboard-content{ padding: 20px 15px; }
	.backendinfo-content .own-two{ margin:0;}
	.backendinfo-lhs { padding-right: 0;}

	.pdt-rhs-top h1{ font-size: 32px; }

	.common-inner-banner p{ font-size: 24px; }

	.subscrip-maindiv .black-part .button{ padding: 12px 22px}

	.mydetail_maindiv .subscription-button { justify-content: center; flex-flow: column; padding: 15px 0px 10px; }
	.mydetail_maindiv .inquire-part .button{ margin-bottom: 15px; }
	.mydetail_maindiv .mydetail-button .inquire-part{  margin-right: 0; }
	.mydetail_maindiv .black-part .button { padding: 15px 32px 14px;}

	.so-lhs{ width: 100%;position: absolute; left: 0; right: 0;top: 0; bottom: 0; display: none;}
	.so-rhs{ width: 100%; padding: 40px 20px 120px; margin-left: 0; }

	.login_mobileheader{ display: block; width: 100%; position: sticky; top: 0; z-index: 4;}

	.login_mobileheader .dashboard-mobilemenu{ justify-content: center; }

	.login_mobileheader .mobile-nav-logo{ position: unset; left: inherit; transform: translateX(0); display: flex;
		justify-content: center;
	}

	.fileup_content, .fileup_link{ width: 100%; justify-content: flex-start;}
	
	.fileup_link{ margin-top: 25px;}
}

@media (max-width: 580px){
    .form-header h1, .form-header p{ text-align: center; }

    .dashboard .nav-tabs>li.active>a, .dashboard .nav-tabs>li>a, .dashboard .nav-tabs>li.active>a:focus, 
    .dashboard .nav-tabs>li.active>a:hover, .dashboard .nav-tabs>li:hover a{
    	background: transparent;
    }

    .dashboard .nav-tabs>li>a { color: #c7c7c7;}
	.dashboard .nav { border-bottom: 5px solid #c7c7c7;}

	footer{ padding: 10px; font-size: 12px; }

	.prompt-box .input-box-small { width: 35px; height: 35px; font-size: 15px; border-radius: 5px;}
	.getstarted-form .two-col{ width: 100%; }
	.your-package ul li { width: 100%;}

	.package-details-middle, .package-details-lower{ width: 100%; }

	.package-middle-container{ flex-flow: column; }

	.package-headingtop h1, .checkout-main-box h1{ font-size: 32px; }

	.package-details-main{ padding: 35px 10px; text-align: center; }

	.package-details-header h4:after{ display: none; }

	.backendinfo-content .button{ letter-spacing: 2px; }

	.back-package { text-align: center;}
	
	.control-country label{ color: #000; }
	.singupform-step-one .input-box:focus ~ label, .singupform-step-one .focused label{ color: #000; }

	.package-main-box, .package-middle-container{ width: 50%; }

	.inquire-part .button{ letter-spacing: 1px; }

	.plan-chose-hedaingdiv{ margin-bottom: 30px; }

	.plan-chose-hedaingdiv h1{ font-size: 32px; margin-bottom: 10px;}

	.subscrip-maindiv .black-part .button{ letter-spacing: 1px; padding: 11px 22px}

	.subscription-detail-lhs h5, .subscription-detail-rhs h5{ font-size: 14px; }

	.subscription-button{ padding: 0 20px 25px; justify-content: flex-start;  }
	.subscrip-maindiv .package-details-top{ padding: 25px 20px; }
	.subscription-button .inquire-part{ margin-right: 20px; }

	.forgot-password-popup .inside-popup-rhs { padding: 10px 15px 30px; }

	#change-password-popup .inside-popup-rhs { padding: 30px 15px; }

	.plan-video a .video-btn-slider{ width: 62px !important; height: 65px;}

	#video-popup .modal-detail-box { height: 265px;}
	#video-popup .modal-body{ padding: 0; }
	#video-popup .common-modal-head .close { right: -20px; top: -20px; width: 40px; height: 40px; }
	#video-popup .modal-content { margin: 0 25px;}

	.tab_cont_form .U_img .input-box::before{ margin: 0;}

	.f_uplod{ justify-content: center; }
	.U_img{ width: 100%;}
	.U_img:first-child{ margin-bottom: 10px; }

	.enterprice-details-bottom li { padding-left: 0;}

	.so-rhs { padding: 40px 20px 80px;}

}
@media (max-width: 480px){
	.getstarted-lhs{padding: 40px 20px;}

	.form-header h1 { font-size: 22px;}
	.form-header p {font-size: 15px;}

	.dashboard .nav-tabs>li.active>a, .dashboard .nav-tabs>li>a, .dashboard .nav-tabs>li.active>a:focus, 
	.dashboard .nav-tabs>li.active>a:hover, .dashboard .nav-tabs>li:hover a{
		font-size: 16px;
		padding: 5px 10px;
	}

	.form-header{ margin: 0 0 10px 0; }

	.get-outlet p {margin-right: 0; margin-bottom: 0px;}
	.getstarted-form .btn-single{ margin-top: 20px; }

	.your-package h1 { font-size: 26px;}
	.your-package ul{ padding-top: 0; }

	.checkout-main-box .card-header { padding: 17px 15px;}

	.checkout-main-box .card-body{ padding: 15px; }

	.billing-chk .inline{ padding-right: 10px; }

	.tab_cont_form .control-para{ font-size: 14px; }

	.dashboard_contant{ padding: 30px 20px; }

	.tab_cont_form .U_img .input-box::before{ padding: 12px 22px 12px; font-size: 14px; margin: 0 50px 5px;}

	.pdt-rhs-top h1{ font-size: 32px; }

	.pay-conf-popup .button{ letter-spacing: 3px; }

	.package-headingtop h1{ font-size: 26px; margin-bottom: 10px; }

	.package-details-bottom li{ padding: 5px 0; font-size: 14px; }

	.package-details-middle, .package-details-lower{ padding: 30px 10px 20px; }

	.package-middle-header h4, .plan-chose-hedaingdiv h4{ font-size: 18px; }

	.package-headingtop p{ font-size: 14px; }

	.package-headingcontent h1{ font-size: 32px; }

	.inquire-part .button{ letter-spacing: normal; font-size: 14px; }

	.package-priceul li{font-size: 14px;}

	.back-package .button{ min-width: auto; padding: 12px 26px; font-size: 14px; }
	.black-part .button{ min-width: 160px; letter-spacing: normal; font-size: 14px; padding: 12px 26px; }
	.black-part.inquire-part .button{ font-size: 13px; min-width: auto;}

	.inquire-plan-part .button{ letter-spacing: 4px; }

	.show_btn_div .button{ letter-spacing: 4px; }

	.pdt-rhs-top h1{ font-size: 26px; }
	.pdt-rhs-top h3{ font-size: 18px; }
	.pdt-rhs-btm h2{ font-size: 24px; }
	.pdt-rhs-btm h4{ margin-bottom: 10px; }

	.package-details-btm{ display: none; }

	.plan-chose-maindiv{ display: block; margin: 20px 0; }

	.subscrip-maindiv .pdt-lhs { width: 150px; height: 150px}
	.subscrip-maindiv .pdt-rhs{ width: calc( 100% - 150px ); padding: 0 0 0 15px;}
	.subscrip-maindiv img{ object-fit: scale-down; }
	.subscription-detail-heading{ justify-content: center; border-radius: 15px 15px 0px 0px; padding: 10px 20px;}
	.subscription-detail-lhs { margin-bottom: 5px; }
	.subscrip-maindiv .black-part .button{ font-size: 13px; letter-spacing: 0.8px; padding: 10px 12px; }

	.notification-button .black-part .button{padding: 10px 16px 8px;font-size: 13px;letter-spacing: 0.8px;margin-left: 8px;}
	.notification-maindiv li{padding: 20px 10px;}
	.notification-maindiv .clear { padding-right: 25px; }
	.notification-maindiv .clear a{ font-size: 12px; }
	.notification-content p { font-size: 13px; }
	.notification-content h5 { font-size: 15px; }
	.notification-heading h1 { margin-bottom: 15px;}

	.forgot-password-popup .pop-whole-lhs-inner h3 { font-size: 22px; margin-bottom: 0;}
	.forgot-password-popup .pop-whole-lhs-inner p{ font-size: 15px; }
	.forgotpassword-cls{ margin-bottom: 0;}

	.tab_cont_form .U_img .input-box { width: 100%;}

	#features_list .package-details-li{font-weight: 600;padding-left: 0;}
	#features_list li{padding-left: 25px;}

	#enquire-popup{ padding: 40px 35px 30px; }

	.subscription-heading h3 { font-size: 20px; margin-bottom: 10px; }

	#pay-conf-popup { padding: 35px 15px 25px; }

}

@media (max-width: 420px){
	.terms-chk .inline{ font-size: 12px;}

	.backendinfo-content .own-two{ min-width: 100%; }

	.package-headingtop h1{ font-size: 20px; }

	.package-headingtop h4{ font-size: 16px; margin-bottom: 10px; }

	.package-details-bottom li{ font-size: 12px; }

	.get-outlet p {margin-bottom: 10px;}

	.package-headingtop p{ font-size: 12px; }
	.package-headingcontent h1{ font-size: 22px; }
	.package-headingcontent sup{ font-size: 14px; }
	.package-headingcontent p{ font-size: 12px; }

	.package-priceul li{ margin: 5px 0; }

	.mobile-package-middle .outlet-number .qty_bx{ width: 140px; padding: 0 40px; height: 40px; }
	.mobile-package-middle .outlet-number .qty_bx span{ width: 40px; }
	.mobile-package-middle .outlet-number p{ font-size: 14px; }

	.inquire-part { margin-top: 20px;}

	.black-part .button{ min-width: 140px; padding: 10px 26px; }

	.inquire-plan-part .button, .show_btn_div .button{ padding: 12px 26px; }

	.f_passwd_div{margin-top: 0;}

	.subscription-button{ justify-content: space-between; }
	.subscription-button .inquire-part { margin-right: 0;}
}

@media (max-width: 380px){
	.mobile-package-middle .outlet-number .qty_bx{ width: 120px; padding: 0 30px; height: 35px; }
	.mobile-package-middle .outlet-number .qty_bx span{ width: 30px; }
	.mobile-package-middle .outlet-number p{ font-size: 12px; }

	.mobile-package-middle .outlet-number .qty_bx span.qty_minus:before{ width: 10px; }

	.black-part .button{ font-size: 12px; padding: 8px 26px; min-width: 120px;}
	.black-part.inquire-part .button { font-size: 12px; padding: 8px 18px;}
	.back-package .button { padding: 10px 26px; font-size: 12px; margin: 30px 0px 0px;}

	.inquire-plan-part .button{ font-size: 12px; padding: 10px 12px; min-width: 100px;}
	.show_btn_div .button{font-size: 14px;padding: 12px 26px;}

	.terms-chk .inline {font-size: 11px;}

	.subscription-button { justify-content: center; flex-flow: column;}
	.subscription-button .inquire-part{ margin-bottom: 5px; }
	.subscription-button .black-part{ text-align: center; }

	.subscription-button .black-part .button { font-size: 14px; padding: 12px 18px; margin-right: 0; min-width: 100%;
	 letter-spacing: 4px;
	}

	.mydetail_maindiv .black-part .button {
	    padding: 14px 14px 13px;
    	font-size: 14px;
    	letter-spacing: 2px;
	}

	.mydetail_maindiv .inquire-part .button {
	    margin-bottom: 5px;
	}
}

@media (max-width: 340px){
	.uploadlogo_div {
	    width: 100%;
	}
}

 

